function hiddenPreload(){
    $(document).ready(function(){
        $('#preload').fadeOut('slow', function(){
        });
    });
}

/* replace all SVG images with inline SVG */
function showSvgPath(){
    $('img.svg,.icon').each(function(){
        var $img = $(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        $.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = $(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, else we gonna set it if we can.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');
    });
}

// function toggleActive() {
//     $('.toggleActive').click(function(event) {
//         $(this).toggleClass('active');
//     });
// }

function onlyoneActive() {
}

$(window).on('load', function() {
	hiddenPreload();

    setTimeout(showSvgPath, 1000);
});



